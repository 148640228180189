import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
const ImageLoader = loadable(() => import("./../common/ImageLoader"));
import { useIsMobile } from "../../hooks";

import logo from "assets/images/logo.svg";

import milaAir3 from "assets/images/landingAir/mila-air-3-alt.jpg";
import milaAir3Webp from "assets/images/landingAir/mila-air-3-alt.jpg?as=webp";
import milaAirMini from "assets/images/landingAir/mila-air-mini.jpg";
import milaAirMiniWebp from "assets/images/landingAir/mila-air-mini.jpg?as=webp";

import accessory01 from "assets/images/landingAir/accessory-img-01.jpg";
import accessory01Webp from "assets/images/landingAir/accessory-img-01.jpg?as=webp";

// import accessory02 from "assets/images/landingAir/accessory-img-02.jpg";
// import accessory02Webp from "assets/images/landingAir/accessory-img-02.jpg?as=webp";

import accessory03 from "assets/images/landingAir/accessory-img-03.jpg";
import accessory03Webp from "assets/images/landingAir/accessory-img-03.jpg?as=webp";

/**
 * @param pathname
 * @param topBarRef
 * @returns {JSX.Element}
 * @constructor
 */

const HeaderRefresh = ({ pathname, topBarRef, headerRef, className }) => {
  const [isSticky, setIsSticky] = useState(false);
  const [isOrder, setIsOrder] = useState(false);
  const [headerOffset, setHeaderOffset] = useState(51);
  const [navOffset, setNavOffset] = useState(0);
  const [activeSubmenu, setActiveSubmenu] = useState("Air Series");
  const [isMenuActive, setIsMenuActive] = useState(false);
  const menuRef = useRef(null);
  const megaMenuRef = useRef(null);
  const observerRef = useRef(null);
  const orderRef = useRef(null);
  const orderBtnRef = useRef(null);

  const isMobile = useIsMobile(1025);

  useLayoutEffect(() => {
    const updateOffsets = () => {
      const topbarHeight = topBarRef?.current?.clientHeight || 0;
      const headerHeight = headerRef?.current?.clientHeight || 0;

      setHeaderOffset(topbarHeight);
      setNavOffset(headerHeight);
    };

    updateOffsets();
    observerRef.current = new ResizeObserver(updateOffsets);

    if (topBarRef?.current) {
      observerRef.current.observe(topBarRef.current);
    }
    if (headerRef?.current) {
      observerRef.current.observe(headerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [topBarRef, headerRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        megaMenuRef.current &&
        !megaMenuRef.current.contains(event.target)
      ) {
        setActiveSubmenu("Air Series");
      }

      if (
        orderRef.current &&
        !orderRef.current.contains(event.target) &&
        !orderBtnRef.current.contains(event.target)
      ) {
        setIsOrder(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const { body } = document;
    body.style.setProperty("--menuOffset", `${headerOffset + navOffset}px`);
  }, [headerOffset, navOffset]);

  const handleMenuItemClick = (text, event) => {
    event.preventDefault();
    setActiveSubmenu(activeSubmenu === text ? null : text);
  };

  const menuHandler = (e) => {
    e.preventDefault();
    setIsMenuActive(!isMenuActive);
    setIsOrder(false);
  };

  const orderHandler = (e) => {
    e.preventDefault();
    setIsOrder(!isOrder);
    setIsMenuActive(false);
  };
  const handleMenuReset = () => {
    setIsMenuActive(false);
    if (!isMobile) {
      setActiveSubmenu(null);
    }
  };
  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 40);
    };

    handleScroll();

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, []);

  const navs = [
    { href: "", text: `Air${isMobile ? " Series" : ""}`, submenu: true },
    { href: "/filters", text: "Filters", submenu: false },
    {
      href: isMobile ? "/performance" : "/performance",
      text: isMobile ? "Performance" : "Performance",
      submenu: false,
    },
    { href: "/app", text: "App", submenu: false },
    { href: "/our-story", text: "Our Story", submenu: false },
  ];

  const navItems = navs.map(({ href, text, submenu }) => {
    return (
      <li className="header__menu-item" key={text}>
        <Link
          to={href || "#"}
          className={`${href === pathname ? "header__menu-item--current" : ""}${
            submenu ? " has-submenu" : ""
          }${activeSubmenu === text ? " header__menu-item--active" : ""}`}
          onClick={(e) =>
            submenu ? handleMenuItemClick(text, e) : handleMenuReset()
          }
        >
          <span data-text={text}>{text}</span>
        </Link>
        {activeSubmenu === "Air Series" && isMobile && submenu && (
          <MegaMenu ref={megaMenuRef} isMobile={isMobile} />
        )}
      </li>
    );
  });

  return (
    <header
      className={`header ${
        (activeSubmenu === navs[0].text || isOrder) && !isMobile
          ? "submenu--active"
          : ""
      }${className} ${isSticky ? "header--sticky" : ""}`}
      ref={headerRef}
      style={{
        "--offsetTop": `${headerOffset}px`,
        "--menuOffset": `${headerOffset + navOffset}px`,
      }}
    >
      <div className="header__inner">
        <div className="container">
          <div className="header__grid">
            <button
              type="button"
              className="header__menu-toggle"
              onClick={(e) => menuHandler(e)}
            >
              {!isMenuActive ? <MenuBar /> : <CloseBar />}
            </button>
            <div className="header__logo">
              <a href="/">
                <span className="screen-reader-text">Mila Air Homepage</span>
                <img src={logo} alt="Mila branding" />
              </a>
            </div>

            <nav
              className={`header__nav${
                isMenuActive ? " header__nav--active" : ""
              }`}
              ref={menuRef}
            >
              <ul className="header__menu">
                {React.Children.toArray(navItems)}
              </ul>
            </nav>
            <div className="header__actions">
              <button
                type="button"
                className="button button--md button--primary button--shadow-primary button--order"
                onClick={(e) => orderHandler(e)}
                ref={orderBtnRef}
              >
                Buy
              </button>
            </div>
          </div>
        </div>
      </div>
      {activeSubmenu === "Air" && !isMobile && (
        <MegaMenu ref={megaMenuRef} isMobile={isMobile} />
      )}
      {isOrder && <OrderNow ref={orderRef} />}
    </header>
  );
};

// const LoginButton = () => {
//   return (
//     <a
//       href="#"
//       className="button button--md button--white button--shadow-dark login-button"
//     >
//       Login
//     </a>
//   );
// };

const MenuBar = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 13.5C6 12.6716 6.67157 12 7.5 12H29.5C30.3284 12 31 12.6716 31 13.5C31 14.3284 30.3284 15 29.5 15H7.5C6.67157 15 6 14.3284 6 13.5Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 22.5C6 21.6716 6.67157 21 7.5 21H29.5C30.3284 21 31 21.6716 31 22.5C31 23.3284 30.3284 24 29.5 24H7.5C6.67157 24 6 23.3284 6 22.5Z"
        fill="black"
      />
    </svg>
  );
};
const CloseBar = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.43934 9.43836C10.0251 8.85258 10.9749 8.85258 11.5607 9.43836L27.117 24.9947C27.7028 25.5805 27.7028 26.5302 27.117 27.116C26.5312 27.7018 25.5815 27.7018 24.9957 27.116L9.43934 11.5597C8.85355 10.9739 8.85355 10.0241 9.43934 9.43836Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.117 9.43836C27.7028 10.0241 27.7028 10.9739 27.117 11.5597L11.5607 27.116C10.9749 27.7018 10.0251 27.7018 9.43934 27.116C8.85355 26.5302 8.85355 25.5805 9.43934 24.9947L24.9957 9.43836C25.5815 8.85258 26.5312 8.85258 27.117 9.43836Z"
        fill="black"
      />
    </svg>
  );
};

const MegaMenu = React.forwardRef((props, ref) => {
  return (
    <div className="mega-menu" ref={ref}>
      <div className="mega-menu__grid">
        <div className="mega-menu__item">
          <div className="menu-product">
            <ImageLoader
              className="menu-product__image img-relative"
              placeholderAspectRatio={1 / 1}
              image={milaAir3}
              webp={milaAir3Webp}
              alt={"Mila Air"}
            />
            <div className="menu-product__content">
              <h3 className="menu-product__title">Mila Air</h3>
              <p className="menu-product__price">
                Starting at $244 <del>$349</del>
                <span>with Auto-Refill</span>
              </p>
              <div className="menu-product__description">
                <p>
                  The award-winning air purifier with the power to handle larger
                  spaces with ease.
                </p>
              </div>
              <a
                href="/performance"
                className="button button--primary button--sm"
              >
                Learn more
              </a>
            </div>
          </div>
        </div>
        <div className="mega-menu__item">
          <div className="menu-product">
            <ImageLoader
              className="menu-product__image img-relative"
              placeholderAspectRatio={1 / 1}
              image={milaAirMini}
              webp={milaAirMiniWebp}
              alt={"Mila Air"}
            />
            <div className="menu-product__content">
              <h3 className="menu-product__title">Mila Air Mini</h3>
              <p className="menu-product__price">
                Starting at $199
                <span>with Auto-Refill</span>
              </p>
              <div className="menu-product__description">
                <p>
                  Your favorite air purifier is now more accessible (and fun!)
                  than ever.
                </p>
              </div>
              <a
                href="https://airmini.milacares.com/"
                className="button button--primary button--sm"
              >
                Learn more
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const ProductsAccordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, []);

  return (
    <div
      className={`order-list__item cart-accordion${
        isOpen ? " cart-accordion--active" : ""
      }`}
    >
      <div className="cart-accordion__header" onClick={toggleAccordion}>
        <h3 className="cart-accordion__title">{title}</h3>
      </div>
      <div
        className="cart-accordion__body"
        style={{
          maxHeight: isOpen ? `${contentHeight}px` : "0",
          overflow: "hidden",
          transition: "max-height 0.3s ease-out",
        }}
        ref={contentRef}
      >
        {children}
      </div>
    </div>
  );
};

const OrderNow = React.forwardRef((props, ref) => {
  return (
    <div className="header__sidebar order-list" ref={ref}>
      <ProductsAccordion title="Choose your Mila">
        <div className="mila-list">
          <div className="mila-item">
            <ImageLoader
              className="mila-item__image img-relative"
              placeholderAspectRatio={1 / 1}
              image={milaAir3}
              webp={milaAir3Webp}
              alt={"Mila Air"}
            />
            <div className="mila-item__content">
              <div className="mila-item__heading">
                <h3 className="mila-item__title">Mila Air</h3>
                <p className="mila-item__price">
                  Starting at $244 <del>$349</del>
                  <span>with Auto-Refill</span>
                </p>
              </div>
              <a
                href="https://shop.milacares.com/"
                className="button button--primary button--sm"
              >
                Learn more
              </a>
            </div>
          </div>
          <div className="mila-item">
            <ImageLoader
              className="mila-item__image img-relative"
              placeholderAspectRatio={1 / 1}
              image={milaAirMini}
              webp={milaAirMiniWebp}
              alt={"Mila Air Mini"}
            />
            <div className="mila-item__content">
              <div className="mila-item__heading">
                <h3 className="mila-item__title">Mila Air Mini</h3>
                <p className="mila-item__price">
                  Starting at $199
                  <span>with Auto-Refill</span>
                </p>
              </div>
              <a
                href="https://airmini.milacares.com/"
                className="button button--primary button--sm"
              >
                Learn more
              </a>
            </div>
          </div>
        </div>
      </ProductsAccordion>

      <ProductsAccordion title="Buy accessories">
        <div className="mila-list">
          <div className="mila-item mila-item--filter">
            <ImageLoader
              className="mila-item__image img-relative"
              placeholderAspectRatio={1 / 1}
              image={accessory01}
              webp={accessory01Webp}
              alt={"Mila Air Filters"}
            />
            <div className="mila-item__content">
              <div className="mila-item__heading">
                <h3 className="mila-item__title">Mila Air Filter</h3>
                <p className="mila-item__price">Starting at $59</p>
                {/* <button
                  type="button"
                  className="button button--white button--sm button--shadow-dark"
                >
                  Filter type <IconAngleDown />
                </button> */}
              </div>
              <a
                href="https://shop.milacares.com/filters"
                className="button button--primary button--sm"
              >
                Learn more
              </a>
            </div>
          </div>

          <div className="mila-item mila-item--filter">
            <ImageLoader
              className="mila-item__image img-relative"
              placeholderAspectRatio={1 / 1}
              image={accessory03}
              webp={accessory03Webp}
              alt={"Mila Air Socks"}
            />
            <div className="mila-item__content">
              <div className="mila-item__heading">
                <h3 className="mila-item__title">Mila Socks (5 pack)</h3>
                <p className="mila-item__price">$50</p>
              </div>
              <a
                href="https://shop.milacares.com/socks"
                className="button button--primary button--sm"
              >
                Learn more
              </a>
            </div>
          </div>
        </div>
      </ProductsAccordion>
    </div>
  );
});

// const IconAngleDown = () => (
//   <svg
//     width="10"
//     height="10"
//     viewBox="0 0 10 10"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path
//       d="M8.4375 3.60547L5 6.2497L1.5625 3.60547"
//       stroke="currentColor"
//       strokeWidth="1.875"
//       strokeLinecap="round"
//       strokeLinejoin="round"
//     />
//   </svg>
// );

export default HeaderRefresh;
